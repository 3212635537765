import axios from "axios";
import { API_BASE_URL } from "./config";

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getPosts = (params) =>
  api.get("/index.php/wp-json/wp/v2/posts", { params });
export const getPost = (id) => api.get("/index.php/wp-json/wp/v2/posts/" + id);
export const getCategories = () =>
  api.get("/index.php/wp-json/wp/v2/categories");

export default api;
