import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Index from "./pages/Index";
import Detail from "./pages/Detail";
import Result from "./pages/Result";
import Category from "./pages/Category";
import "./index.css";

ReactDOM.render(
  <Router>
    <App>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="/result" element={<Result />} />
        <Route path="/category" element={<Category />} />
      </Routes>
    </App>
  </Router>,
  document.getElementById("root")
);
