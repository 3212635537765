import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "./api";
const App = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    getCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.error(error));
    if (process.env.NODE_ENV != "development") {
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleSearch = (event) => {
    event.preventDefault();
    const keyword = event.target.elements.keyword.value;
    window.location.href = `/result?keyword=${keyword}`;
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <header>
        <h1>
          <Link to="/">Text.gs</Link>
        </h1>
        <form onSubmit={handleSearch}>
          <input type="text" name="keyword" placeholder="搜索一下..." />
          <button type="submit">搜索</button>
        </form>
        <nav>
          <ul>
            <li>
              <Link to="/">首页</Link>
            </li>
            {categories.map((category) => (
              <li key={category.id}>
                <Link to={`/category?id=${category.id}`}>{category.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <main>{children}</main>
      <footer>
        <p>&copy; 2024 Text.gs</p>
      </footer>
      {showScrollButton && (
        <div className="back-to-top" onClick={scrollToTop}>
          &uarr;
        </div>
      )}
    </div>
  );
};

export default App;
