import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getPosts } from "../api";
import LinearProgress from "@mui/material/LinearProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Index = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 24;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "text.gs 记录与文字有关的一切";
    setLoading(true);
    getPosts({ page, per_page: pageSize })
      .then((response) => {
        setPosts(response.data);
        window.scrollTo(0, 0);
        if (response.data.length == 0) {
          setPage(1);
          getPosts();
          toast.warning("没有更多内容了");
        }
        function clampText(element, lines) {
          const lineHeight = parseInt(
            window.getComputedStyle(element).lineHeight
          );
          const maxHeight = lineHeight * lines;
          element.style.maxHeight = `${maxHeight}px`;
          element.style.overflow = "hidden";
        }

        document.querySelectorAll("main li div").forEach((element) => {
          clampText(element, 3);
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [page]);

  return (
    <div>
      {loading && <LinearProgress />}
      {posts.length == 0 && loading == false ? (
        <div className="noFound">¯\_(ツ)_/¯ 暂无内容</div>
      ) : null}
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <Link to={`/detail?id=${post.id}`}>
              {decodeURIComponent(post.title.rendered)}
            </Link>
            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            />
            <dir className="more">
              <span>{post.date.replace("T", " ")}</span>
            </dir>
          </li>
        ))}
      </ul>
      <div className="Pager">
        <button onClick={() => setPage(page - 1)} disabled={page === 1}>
          上一页
        </button>
        <button onClick={() => setPage(page + 1)}>下一页</button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Index;
