import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getPosts } from "../api";
import LinearProgress from "@mui/material/LinearProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Category = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 24;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.get("id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPosts({ categories: category, page, per_page: pageSize })
      .then((response) => {
        setPosts(response.data);
        window.scrollTo(0, 0);
        fetch(
          `https://wp.text.gs/index.php/wp-json/wp/v2/categories/${category}`
        )
          .then((response) => response.json())
          .then((data) => {
            // 打印文章所属分类目录名称
            console.log("文章所属分类目录名称:", data.name);
            document.title = data.name + " - text.gs";
          })
          .catch((error) => console.error("获取分类目录信息时出错:", error));

        if (response.data.length == 0) {
          setPage(1);
          getPosts();
          toast.error("没有更多内容了");
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [category, page]);

  if (loading) return <LinearProgress />;

  if (!posts) return <div>Loading...</div>;

  return (
    <div>
      {posts.length == 0 && loading == false ? (
        <div className="noFound">¯\_(ツ)_/¯ 暂无内容</div>
      ) : null}
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <Link to={`/detail?id=${post.id}`}>
              {decodeURIComponent(post.title.rendered)}
            </Link>
            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            />
            <dir className="more">
              <span>{post.date.replace("T", " ")}</span>
            </dir>
          </li>
        ))}
      </ul>
      <div className="Pager">
        <button onClick={() => setPage(page - 1)} disabled={page === 1}>
          上一页
        </button>
        <button onClick={() => setPage(page + 1)}>下一页</button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Category;
