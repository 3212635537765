import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPost } from "../api";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";

const Detail = () => {
  const [post, setPost] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  useEffect(() => {
    setLoading(true);
    getPost(id)
      .then((response) => {
        setPost(response.data);
        document.title = response.data.title.rendered + " - text.gs";
        window.scrollTo(0, 0);
        fetch(
          `https://wp.text.gs/index.php/wp-json/wp/v2/categories/${response.data.categories[0]}`
        )
          .then((response) => response.json())
          .then((data) => {
            // 打印文章所属分类目录名称
            console.log("文章所属分类目录名称:", data.name);
            setCategory(data.name);
          })
          .catch((error) => console.error("获取分类目录信息时出错:", error));
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <LinearProgress />;

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <h3>{post.title.rendered}</h3>
      <span>
        {post.date.replace("T", " ")}&nbsp;
        <Link to={"/category?id=" + post.categories[0]}>{category}</Link>
      </span>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: post.content.rendered }}
        style={{ lineHeight: "50px" }}
      />
    </div>
  );
};

export default Detail;
